.customerNotificationWrapper {
  width: 100%;
}

.customerNotificationWrapper.success {
  background-color: var(--color-nyanza);
}

.customerNotificationWrapper.warning {
  background-color: var(--color-sand);
}

.customerNotificationWrapper.error {
  background-color: var(--color-rose);
}

.customerNotificationWrapper.notice {
  background-color: var(--color-sky);
}

.customerNotificationInner {
  composes: gridLargeWrapper from '@/global/style/Layout.module.css';
}
