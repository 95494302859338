.accountsWrapper {
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: var(--spacing-2);
}

.accountsWrapperMobile {
  max-height: calc(100vh - 460px);
}

.accountsWrapperDesktop {
  max-height: calc(100vh - 540px);
}

.menuOverlay {
  position: absolute;
  z-index: 1000;
  width: 100vw;
  background-color: var(--color-white);
  box-shadow: var(--shadow-default);
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  top: 65px;
  right: calc(-1 * (var(--spacing-2) * 2 + 44px));
  overflow: auto;
  /* Adjust for b2c/b2b top header + header height */
  height: calc(100vh - 65px - var(--spacing-3));
}

.menuOverlayOpen {
  visibility: visible;
  opacity: 1;
}

.menuOverlayApp {
  position: fixed;
  top: 81px;
  left: 0px;
  z-index: 100;
  /* Adjust for header and show footer */
  height: calc(100vh - 81px - 104px);
}

.menuOverlayDesktop {
  width: 375px;
  border-radius: var(--border-radius-md);
  height: auto;
  right: var(--spacing-1);
  top: 80px;
}

.menuOverlayDesktopMd {
  top: 92px;
}

.menuOverlayInner {
  padding: var(--spacing-3) var(--spacing-2);
}

.styledButton {
  background-color: var(--color-white);
  border: 1px solid var(--color-dark-gray2);
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-2) var(--spacing-15) var(--spacing-2) var(--spacing-2);
  height: auto;
  font-size: 1rem;
  font-weight: var(--font-weight-regular);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.styledButton span {
  text-align: left;
  width: 85%;
  margin-top: 2px;
}

.styledButton:hover {
  background-color: var(--color-mine-shaft-hover);
}

.styledButton:active {
  background-color: var(--color-mine-shaft-active);
}

.styledButton:focus-within {
  border: 1px solid var(--color-transparent);
  outline: 2px solid var(--color-ocean);
}
