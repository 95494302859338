.complianceSection {
  width: 100%;
}

.listWrapper {
  display: grid;
  margin-bottom: var(--spacing-4);
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-2);
}

.checkboxWrapper:last-of-type {
  margin-bottom: 0px;
}

.checkboxWrapper > label {
  max-width: 100%;
  margin: 0 var(--spacing-2);
}

.checkboxWrapper > span {
  margin-left: var(--spacing-6);
}
