.hamburgerMenuOverlay {
  width: 100vw;
  height: 100vh;
  background-color: var(--color-white);
  position: absolute;
  top: 73px;
  left: 0px;
  z-index: 100;
}

.hamburgerMenuContainer {
  width: 100%;
  background-color: var(--color-white);
}

.iconContainer {
  position: relative;
}

.menuLink.menuLink {
  width: 100%;
  margin: 0px;
  padding: var(--spacing-15) var(--spacing-2);
  text-decoration: none;
}

.menuLinkIcon.menuLinkIcon {
  color: var(--color-mine-shaft);
  display: flex;
  text-align: left;
  padding-left: var(--spacing-1);
}

.menuLinkIcon.menuLinkIcon span {
  position: relative;
  top: -2px;
  left: var(--spacing-05);
}

@media (min-width: 992px) {
  .hamburgerMenuOverlay {
    top: 81px;
  }
}
