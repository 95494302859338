.radioLinkButtonContainer {
  cursor: pointer;
}

.radioLinkButtonContainer > div {
  height: 88px;
  align-items: center;
}

.radioLinkButtonContainer.selected > div {
  background-color: var(--color-mine-shaft-active);
}

.radioCardBody {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: var(--spacing-2);
  width: 100%;
}

.radioCardInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.radioChildContainer {
  width: 100%;
  /* force new line by taking up full width */
  grid-column: 1 / -1;
}
