.badgeContainer {
  border-radius: var(--border-radius-md);
  display: inline-block;
  transition: all 150ms ease-in;
  transition-property: background-color, color;
  user-select: none;
  text-align: center;
  margin: 0;
  padding: var(--spacing-1) var(--spacing-15);
  user-select: auto;
  font-weight: var(--font-weight-black);
}

.badgeContainerMargin {
  margin: 0 var(--spacing-15);
}

.badgeMineShaft {
  border-color: var(--color-mine-shaft);
  background-color: var(--color-mine-shaft);
  color: var(--color-white);
}

.badgeRose {
  border-color: var(--color-rose);
  background-color: var(--color-rose);
  color: var(--color-hallon1);
}

.badgeLemon3 {
  border-color: var(--color-lemon3);
  background-color: var(--color-lemon3);
  color: var(--color-hallon1);
}

.badgeBlue {
  border-color: var(--color-sky);
  background-color: var(--color-sky);
  color: var(--color-blue);
}

.badgeForest {
  border-color: var(--color-nyanza);
  background-color: var(--color-nyanza);
  color: var(--color-forest);
}

.badgeGray {
  border-color: var(--color-gray2);
  background-color: var(--color-gray2);
  color: var(--color-mine-shaft);
}

.badgeText.badgeText {
  position: relative;
  top: 1px;
  color: inherit;
}

.badgeTextSlim.badgeTextSlim {
  line-height: 1;
  font-weight: var(--font-weight-regular);
}
