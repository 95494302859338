.appLoginHeader {
  background-color: var(--color-hallon1);
  width: 100%;
  height: 184px;
  padding: var(--spacing-2);
  display: flex;
  justify-content: center;
}

.appLoginHeader.appLoginHeader svg {
  width: 200px;
  height: 40px;
}
