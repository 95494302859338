.accountRadioButtonContainer {
  max-height: 325px;
  overflow: auto;
}

.accountRadioButton.accountRadioButton > div {
  align-items: flex-start;
  height: auto;
}

.accountRadioButton.noPhoneNumberAccount > div {
  align-items: center;
  height: 56px;
}
