.appHeader {
  position: fixed;
  height: 81px;
  width: 100%;
  background: var(--color-hallon1);
  top: 0;
  left: 0;
  z-index: 10;
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-4);
  box-shadow: var(--shadow-menu);
}

.appHeaderInner {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: min-content auto min-content;
  align-items: baseline;
  padding: var(--spacing-2);
}

.headerIconButton {
  width: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: all;
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-1);
  gap: 4px;
  position: relative;
  top: -4px;
}

.headerIconButtonActive {
  background-color: var(--color-white-active);
  width: 58px;
}

.headerIconButtonActive::after {
  content: '';
  height: 4px;
  width: calc(100% - 12px);
  left: 6px;
  position: absolute;
  bottom: -9px;
  background-color: var(--color-gray1);
}

.headerIconButton span {
  width: max-content;
}

.appHeaderSpacer {
  height: 81px;
}
