.hamburgerMenuWrapper {
  display: block;
  margin-left: var(--spacing-2);
}

.mainIconLinkContainer {
  position: relative;
}

.activeCartIndicator {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: var(--color-hallon1);
  border: 1px solid var(--color-white);
  border-radius: 100%;
  z-index: 5;
  top: 10px;
  left: 27px;
}

@media (min-width: 1148px) {
  .hamburgerMenuWrapper {
    display: none;
  }
}
